
















































































































































































import { Component, Vue, Ref, Watch } from 'vue-property-decorator'
import { CurvaAbc, FiltroDeCurvaAbc, TotalizadoresDeCurvaAbc } from '@/models/CurvaAbc'
import DataTableDeCrudPaginado from '@/components/ui/DataTableDeCrudPaginado.vue'
import RangeDatePicker from '@/components/ui/RangeDatePicker.vue'
import moment from 'moment'
import { Page } from '@/models'
import axios, { CancelTokenSource } from 'axios'
import { nextTick } from '@/shareds/utils'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { FindRelatorioUseCase } from '@/usecases/relatorio/FindRelatorioUseCase'
import { formatDate } from '@/shareds/date/date-utils'
import SeletorDeLojasDoUsuario from "@/components/loja/SeletorDeLojas.vue"
import { formatarMoeda } from '@/shareds/formatadores'
import RodapePersonalizado from '@/components/ui/data-tables/RodapePersonalizado.vue'
import { DataOptions } from 'vuetify'

@Component({
	components: {
		DataTableDeCrudPaginado,
		RangeDatePicker,
		SeletorDeLojasDoUsuario,
		RodapePersonalizado,
	},
})
export default class TelaDeRelatoriosCurvaAbc extends Vue {
	@Ref() dataTablePaginado!: DataTableDeCrudPaginado
	findRelatorioUseCase = new FindRelatorioUseCase()

	filtro: FiltroDeCurvaAbc = {
		codigos: [],
		curvaAbc: [],
		horas: [null, null],
		datas: [],
		lojaId: null,
	}
	carregando = false
	filtroAlterado = false
	headers = [
		{ text: 'Sku Produto', value: 'codigoDoProduto', sortable: false},
		{ text: 'Produto', value: 'nomeCompleto', sortable: false},
		{ text: 'Curva ABC', value: 'curvaAbc' },
		{ text: 'Preco custo unitário', value: 'displayPrecoCustoUnitario', width: '100', sortable: false},
		{ text: 'Preco venda unitário', value: 'displayPrecoVendaUnitario', width: '100', sortable: false},
		{ text: 'Qtd. vendida', value: 'quantidadeVendida', sortable: false},
		{ text: 'Valor total da venda', value: 'displayValorTotalItens', width: '100', sortable: false},
		{ text: 'Participação venda', value: 'displayParticipacaoVenda', sortable: false},
		{ text: 'Percentual acumulado', value: 'displayPercentualAcumulado', width: '100', sortable: false},
		{ text: 'Valor de custo', value: 'displayValorCusto', width: '100', sortable: false},
		{ text: 'Qtd. estoque', value: 'quantidadeEstoque', sortable: false},
		{ text: 'Custo de estoque', value: 'displayCustoDeEstoque', width: '100', sortable: false},
		{ text: 'Participação estoque', value: 'displayParticipacaoDeEstoque' , sortable: false},
		{ text: 'Dias de venda', value: 'diasDeVenda' , sortable: false},
		{ text: 'Cobertura de 30 dias', value: 'coberturaDe30Dias' , sortable: false},
		{ text: 'Sugestão de reposição', value: 'sugestaoDeReposicao' , sortable: false},
		{ text: 'Valor', value: 'valor' , sortable: false},
		{ text: 'Status', value: 'status' , sortable: false},
	]
	pagina: Page<CurvaAbc> | null = null
	curvasAbc: CurvaAbc[] = []
	paginacao: DataOptions = {
		page: 0,
		itemsPerPage: 10,
		sortBy: [],
		sortDesc: [],
		groupBy: [],
		groupDesc: [],
		multiSort: false,
		mustSort: false,
	}
	cancelToken: CancelTokenSource | null = null
	totalRegistros = -1

	dataInvalida = false
	rangeData = 0
	limpeza = false

	totalizadores: TotalizadoresDeCurvaAbc | null = null

	limparFiltros() {
		this.limpeza = true
		this.filtro = {
			codigos: [],
			curvaAbc: [],
			horas: [null, null],
			datas: [],
			lojaId: null,
		}
		this.totalizadores = null
		this.curvasAbc = []
		this.carregando = false
		setTimeout(() => this.limpeza = false, 1000)
	}

	calculaRangeData(){
		if(!this.filtro.datas[0] || !this.filtro.datas[1]) this.dataInvalida = true
		if(this.isReverseData()) {
			this.filtro.datas.reverse()
		}
		if(this.filtro.datas.length > 0) {
			const starts = moment(this.filtro.datas[0]+' 00:00:00','YYYY/MM/DD')
			let ends   = moment(this.filtro.datas[1]+' 00:00:00','YYYY/MM/DD')
			if(!this.filtro.datas[1]) {
				const now = new Date().toISOString().substring(0,10)
				ends = moment(formatDate(now) + ' 00:00:00','YYYY/MM/DD')
			}
			this.rangeData = Math.abs(starts.diff(ends,'days'))
			if(this.rangeData > 30) this.dataInvalida = true
		}
	}

	isReverseData() {
		if(!this.filtro.datas[0] || !this.filtro.datas[1]) return false
		if(this.filtro.datas[1] < this.filtro.datas[0]) return true
	}

	gerarDataHora(data?: string | null, hora?: string | null): string | undefined {
		return moment(`${data} ${hora}`,  'YYYY-MM-DD HH:mm').toISOString(true)
	}

	get filtrosFormatados() {
		const dataHoraInicial = this.gerarDataHora(this.filtro.datas[0], this.filtro.horas[0] || '00:00')
		const dataHoraFinal = this.gerarDataHora(this.filtro.datas[1], this.filtro.horas[1] || '23:59')
		const novoFiltro = {
			dataHoraInicial,
			dataHoraFinal,
			curvaAbc: Array.isArray(this.filtro.curvaAbc) ? this.filtro.curvaAbc.join(',') : this.filtro.curvaAbc || undefined,
			codigos: Array.isArray(this.filtro.codigos) ? this.filtro.codigos.join(',') : this.filtro.codigos || undefined,
			lojaId: this.filtro.lojaId,
		}
		return novoFiltro
	}

	async aplicarFiltros(filtro: any) {
		this.calculaRangeData()
		if(this.limpeza == false) {
			if (!this.filtro.lojaId) AlertModule.setError('Selecione uma loja para prosseguir')
			if (!this.filtro.datas[0]) AlertModule.setError('Selecione um período')
			if (this.rangeData > 30) AlertModule.setError('Seleção inválida! Selecione um período de até 30 dias.')
		}
		if(filtro) {
			this.filtro = {
				...this.filtro,
				...filtro,
			}
		}
		if(!this.dataInvalida) this.buscar()
	}

	async buscar() {
		await nextTick()
		try {
			this.carregando = true

			this.cancelToken = axios.CancelToken.source()
			const axiosConfig = {
				cancelToken: this.cancelToken.token,
			}
			if (!this.filtro.lojaId) return []
			if (this.filtroAlterado) 
				this.totalizadores = await this.findRelatorioUseCase.getTotaisDeCurvaAbc({...this.filtrosFormatados}, axiosConfig)

			this.filtroAlterado = false
			const pagina = await this.findRelatorioUseCase.listCurvaAbc({
				...this.filtrosFormatados,
				...this.paginacao,
				size: this.paginacao.itemsPerPage,
			}, axiosConfig)
			this.curvasAbc = pagina.content
			this.totalRegistros = pagina.totalElements
			this.paginacao.page = pagina.number
		} catch (e) {
			AlertModule.setError(e)
		} finally {
			this.carregando = false
		}
	}

	get itensFormatados() {
		return this.curvasAbc.map(curvasAbc => ({
			...curvasAbc,
			displayPrecoCustoUnitario: !curvasAbc.precoCustoUnitario ? '0,00' : 'R$ ' + formatarMoeda(curvasAbc.precoCustoUnitario),
			displayPrecoVendaUnitario: !curvasAbc.precoVendaUnitario ? '0,00' : 'R$ ' + formatarMoeda(curvasAbc.precoVendaUnitario),
			displayValorTotalItens: !curvasAbc.valorTotalItens ? '0,00' : 'R$ ' + formatarMoeda(curvasAbc.valorTotalItens),
			displayParticipacaoVenda: !curvasAbc.participacaoVenda ? '0%' : curvasAbc.percentualAcumulado + '%',
			displayPercentualAcumulado: !curvasAbc.percentualAcumulado ? '0%' : curvasAbc.percentualAcumulado + '%',
			displayValorCusto: !curvasAbc.valorCusto ? '0,00' : 'R$ ' + formatarMoeda(curvasAbc.valorCusto),
			displayCustoDeEstoque: !curvasAbc.custoDeEstoque ? '0,00' : 'R$ ' + formatarMoeda(curvasAbc.custoDeEstoque),
			displayParticipacaoDeEstoque: !curvasAbc.particiacaoDeEstoque ? '0%' : curvasAbc.particiacaoDeEstoque + '%',
		}))
	}

	navegar(page) {
		this.paginacao.page += page
		this.buscar()
	}

	@Watch('filtro', { deep: true})
	onChangeFiltro() { 
		this.filtroAlterado = true
	}

}
