var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.aplicarFiltros.apply(null, arguments)}}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('v-text-field',{attrs:{"label":"Sku Produto","multiple":"","clearable":""},model:{value:(_vm.filtro.codigos),callback:function ($$v) {_vm.$set(_vm.filtro, "codigos", $$v)},expression:"filtro.codigos"}})],1),_c('v-col',{attrs:{"cols":"2","sm":""}},[_c('SeletorDeLojasDoUsuario',{attrs:{"label":"Loja","clearable":""},model:{value:(_vm.filtro.lojaId),callback:function ($$v) {_vm.$set(_vm.filtro, "lojaId", $$v)},expression:"filtro.lojaId"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-autocomplete',{attrs:{"label":"Curva ABC","multiple":"","items":['A', 'B', 'C']},model:{value:(_vm.filtro.curvaAbc),callback:function ($$v) {_vm.$set(_vm.filtro, "curvaAbc", $$v)},expression:"filtro.curvaAbc"}})],1),_c('v-col',{attrs:{"cols":"4","sm":"3"}},[_c('RangeDatePicker',{attrs:{"inicial":{
								label: 'Data Inicial',
								clearable: true,
							},"final":{
								label: 'Data Final',
								clearable: true,
							}},model:{value:(_vm.filtro.datas),callback:function ($$v) {_vm.$set(_vm.filtro, "datas", $$v)},expression:"filtro.datas"}})],1),_c('v-col',{attrs:{"cols":"1","sm":""}},[_c('v-row',{staticClass:"d-flex mt-4",attrs:{"justify":"center"}},[_c('v-btn',{staticClass:"mr-2",attrs:{"width":"110","color":"primary","type":"submit","loading":_vm.carregando,"disabled":_vm.carregando}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-magnify ")]),_vm._v(" Aplicar ")],1),_c('v-btn',{attrs:{"width":"110","type":"submit","loading":_vm.carregando,"disabled":_vm.carregando},on:{"click":_vm.limparFiltros}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-broom ")]),_vm._v(" Limpar ")],1)],1)],1)],1)],1)],1)],1),_c('v-card',[_c('v-data-table',{ref:"dataTablePaginado",attrs:{"headers":_vm.headers,"items":_vm.itensFormatados,"loading":_vm.carregando,"hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item.nomeCompleto",fn:function(ref){
							var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","max-width":"200"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
							var on = ref.on;
return [_c('div',_vm._g({staticClass:"nome-completo"},on),[_vm._v(" "+_vm._s(item.nomeCompleto)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.nomeCompleto))])])]}},{key:"body.append",fn:function(ref){
							var headers = ref.headers;
return [(_vm.totalizadores)?_c('tr',[_c('td',{attrs:{"colspan":headers.length}},[_c('div',{staticClass:"d-flex justify-space-around"},[_c('strong',[_vm._v(" Preço Custo Total: "+_vm._s(_vm.totalizadores.precoCustoTotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }))+" ")]),_c('strong',[_vm._v(" Preço Venda Total: "+_vm._s(_vm.totalizadores.precoVendaTotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }))+" ")]),_c('strong',[_vm._v(" Quantidade Vendida Total: "+_vm._s(_vm.totalizadores.quantidadeVendidaTotal)+" ")]),_c('strong',[_vm._v(" Faturado Total: "+_vm._s(_vm.totalizadores.valorTotalFaturado.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }))+" ")]),_c('strong',[_vm._v(" Custo Total: "+_vm._s(_vm.totalizadores.valorCustoTotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }))+" ")]),_c('strong',[_vm._v(" Quantidade Estoque Total: "+_vm._s(_vm.totalizadores.quantidadeEstoqueTotal)+" ")]),_c('strong',[_vm._v(" Custo Estoque Total: "+_vm._s(_vm.totalizadores.custoEstoqueTotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }))+" ")])])])]):_vm._e()]}},{key:"footer",fn:function(){return [_c('RodapePersonalizado',{attrs:{"paginacao":_vm.paginacao,"total":_vm.itensFormatados.length,"loading":_vm.carregando},on:{"update:paginacao":function($event){_vm.paginacao=$event},"navegar":_vm.navegar}})]},proxy:true}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }